import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Input,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import SubCard from "../../ui-component/cards/SubCard";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import axios from "axios";
import LoadingButton from "@mui/lab/LoadingButton";
import { CSVLink } from "react-csv";
import { Autocomplete } from "@mui/material";
import moment from "moment";

const headers = [
  { label: "Seller Id", key: "uniqueSellerId" },
  { label: "Product Name", key: "name" },
  // { label: "phoneNumber", key: "phoneNumber" },
  { label: "Quantity", key: "quantity" },
];

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  root: {
    width: "100%",
  },
  containerTable: {
    maxHeight: 440,
  },
}));

export default function FloridaProductWithSeller() {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [selectProductId, setSelectProductId] = useState(null);
  const [floridaProduct, setFloridaProduct] = useState([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("all");
  const [date, setDate] = useState({
    start: moment().clone().startOf("month").format("YYYY-MM-DD"),
    end: new Date().toISOString().slice(0, 10),
  });

  const getFloridaProductList = () => {
    axios
      .get(process.env.REACT_APP_PATH + "/role1/getFloridaProductsList")
      .then((res) => {
        if (res.data.success) {
          setFloridaProduct(res.data.data);
        }
      })
      .catch((error) => {
        console.log("error of florida product list", error);
      });
  };

  const getSellerWithFloridaOrder = () => {
    axios
      .post(process.env.REACT_APP_PATH + "/role1/getProductsorderwithSeller", {
        startDate: date.start,
        endDate: date.end,
        productId: selectProductId,
        status: status == "all" ? undefined : [3],
      })
      .then((res) => {
        if (res.data.success) {
          setData(res.data.data);
        }
      })
      .catch((error) => {
        console.log("error of florida orders", error);
      });
  };

  useEffect(() => {
    getFloridaProductList();
  }, []);

  return (
    <form className={classes.container} noValidate>
      <Grid container xs={12} spacing={2} item alignItems="center" rowGap={1}>
        <Grid xs={12} sm={6} md={3} lg={3} item>
          <Autocomplete
            getOptionLabel={(option) => option?.name || ""}
            options={floridaProduct}
            key={(op) => op._id.toString()}
            onChange={(event, newValue) => {
              if (newValue?._id) {
                setSelectProductId(newValue._id);
              } else {
                setSelectProductId(null);
              }
            }}
            renderInput={(params) => (
              <TextField {...params} label="Product List" />
            )}
            renderOption={(props, option) => (
              <li {...props} key={option._id}>
                {option.name}
              </li>
            )}
          />
        </Grid>
        <Grid xs={12} sm={6} md={2} lg={2} item>
          <TextField
            id="datetime-local"
            label="Start Date"
            type="date"
            defaultValue={date.start}
            fullWidth
            onChange={(e) =>
              setDate({
                ...date,
                start: e.target.value,
              })
            }
          />
        </Grid>
        <Grid xs={12} sm={6} md={2} lg={2} item>
          <TextField
            id="datetime-local"
            label="End Date"
            type="date"
            defaultValue={date.end}
            fullWidth
            onChange={(e) =>
              setDate({
                ...date,
                end: e.target.value,
              })
            }
          />
        </Grid>
        <Grid xs={12} sm={6} md={2} lg={2} item>
          <Select
            fullWidth
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            label="Status"
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="3">Delivered</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} sm={6} md={2} lg={2}>
          <LoadingButton
            loadingIndicator="Loading..."
            disabled={selectProductId == null}
            loading={loading}
            variant="contained"
            fullWidth
            onClick={() => getSellerWithFloridaOrder()}
          >
            Submit
          </LoadingButton>
        </Grid>
        {data.length > 0 && (
          <Grid item xs={12} sm={6} md={2} lg={2} >
            <CSVLink
              headers={headers}
              filename={`${date.start}to${date.end}floridaPurchasebyseller.csv`}
              data={data}
              title="Download"
              style={{
                textDecoration: "none",
                border: "1px solid #999",
                padding: "10px 20px",
                backgroundColor: "#95c7f1",
                width:'100%'  
              }}
            >
              DownLoad
            </CSVLink>
          </Grid>
        )}
      </Grid>
    </form>
  );
}
