import CryptoJS from "crypto-js";

const salt = "6d090796-ecdf-11ea-adc1-0242ac112345";

export const encryptData = (data) =>
  CryptoJS.AES.encrypt(JSON.stringify(data), salt).toString();

export const decryptData = (ciphertext) => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, salt);
  try {
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (err) {
    return null;
  }
};

export const getDecryptDataFromLocal = (key) => {
  let localData = localStorage.getItem(key);
  if (localData) {
    let data = decryptData(localData);
    return data.toString();
  }
};
export const setencryptDataInLocal = (key, data) => {
  let enData = encryptData(data);
  localStorage.setItem(key, enData);
};
