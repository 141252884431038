import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Divider, Grid, Switch, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import { Alert, Button } from "@material-ui/core";
import { CheckBox } from "@mui/icons-material";

const stylesCSS = {
  input: {
    "& input[type=number]": {
      MozAppearance: "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
  },
};

export default function Ordershippopup({
  openShipmentPopup,
  setShipmentPopup,
  awbSubmissionData,
  bulkOrder = false,
  bulkOrderFromRocket = false,
  isrocketOrPorter=false
}) {
  const [inputData, setInputData] = useState(0);

  const [packagingDetails, setPackingDetails] = useState([]);

  const [disableBtn, setDisableBtn] = useState(false);

  const addMoreFields = (number) => {
    let tempObj = [];

    for (let i = 0; i < Number(number); i++) {
      tempObj.push({
        units: 1,
        weight: 9,
        length: 9,
        height: 9,
        width: 9,
        display_in: "inch",
      });
    }
    setPackingDetails(tempObj);
  };

  const submitData = (e) => {
    e.preventDefault();
    setDisableBtn(true);
    // let url = bulkOrder?`${process.env.REACT_APP_PATH}/products/shipBulkOrderFromUc` : openShipmentPopup?.type == 'unicommerce' ? `${process.env.REACT_APP_PATH}/products/shipOrderFromUc` : `${process.env.REACT_APP_PATH}/products/shipFloridaOrder`
    let url = null;
    if (bulkOrderFromRocket) {
      url = `${process.env.REACT_APP_PATH}/products/shipBulkOrderFromRocketBox`;
    } else if (bulkOrder && !bulkOrderFromRocket) {
      url = `${process.env.REACT_APP_PATH}/products/shipBulkOrderFromUc`;
    } else if (openShipmentPopup?.type == "unicommerce") {
      url = `${process.env.REACT_APP_PATH}/products/shipOrderFromUc`;
    } else {
      url = `${process.env.REACT_APP_PATH}/products/shipFloridaOrder`;
    }

    const dataObj = {
      orderId: openShipmentPopup.orderId,
      packagingUnitDetails: packagingDetails,
      isrocketOrPorter:isrocketOrPorter,
      emergentOrFlorida:openShipmentPopup?.organizations||null
    };
    if(!bulkOrderFromRocket){
        delete dataObj.isrocketOrPorter
    }
    axios({
      method: "POST",
      url: url,
      data: dataObj,
    })
      .then((res) => {
        if (res.data.success) {
          setDisableBtn(false);
          setPackingDetails({
            units: null,
            weight: null,
            length: null,
            height: null,
            width: null,
            display_in: "inch",
          });
          res?.data?.data?.labelUrl &&
            window.open(res?.data?.data?.labelUrl, "_blank");
          setShipmentPopup({
            open: false,
            orderId: null,
            isrocketOrPorter:false
          });

          alert("orders shipped successful");
          window.location.reload();
        } else {
          let error = res.data.message || "";
          if (openShipmentPopup?.type == "unicommerce") {
            alert(error);
            window.location.reload();
          } else {
            if (error == "") {
              for (let key in res?.data?.message) {
                if (res?.data?.message?.hasOwnProperty(key)) {
                  error = res?.data?.message[key];
                  break;
                }
              }
            }
            alert(error);
          }

          setDisableBtn(false);
        }
      })
      .catch((err) => {
        let error = err?.response?.data?.message || "";
        if (openShipmentPopup?.type == "unicommerce") {
          alert(error);
          window.location.reload();
        } else {
          if (error == "") {
            for (let key in err?.response?.data?.message) {
              if (err?.response?.data?.message?.hasOwnProperty(key)) {
                error = err?.response?.data?.message[key];
                break;
              }
            }
          }
          alert(error);
        }
        setDisableBtn(false);
      });
  };

  const onValueChange = (e, ind) => {
    const data = [...packagingDetails];
    data[ind][e.target.name] = e.target.value;
    setPackingDetails(data);
  };

  function handleClose() {
    setPackingDetails({
      units: null,
      weight: null,
      length: null,
      height: null,
      width: null,
      display_in: "inch",
    });
    setShipmentPopup({
      open: false,
      orderId: null,
      isrocketOrPorter:false
    });
    window.location.reload()
  }

  return (
    <div>
      <Dialog
        open={openShipmentPopup.open}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Grid container>
            <Grid item lg={6}>
              <Typography variant="h3">
                {openShipmentPopup?.orderName == "shiprocket"
                  ? `Orders Ship On ${openShipmentPopup?.title ||'Shiprocket'}`
                  : "Orders Ship On Rocket Box"}
              </Typography>
            </Grid>
            <Grid item lg={6} justifyContent={"flex-end"} display={"flex"}>
              <Button variant="outlined" onClick={handleClose}>
                Close
              </Button>
            </Grid>
          </Grid>
        </DialogTitle>
        <Grid container>
          <Grid item lg={12}>
            <Divider />
          </Grid>
        </Grid>
        <DialogContent>
          {openShipmentPopup?.orderName == "rocketBox" && (
            <Grid container>
              <Grid item lg={8} md={8} sm={12}>
                <DialogContentText id="alert-dialog-description">
                  <TextField
                    type="text"
                    name="numofbox"
                    value={inputData}
                    onChange={(e) => setInputData(e.target.value)}
                    placeholder="enter number of box"
                    fullWidth
                  />
                </DialogContentText>
              </Grid>
              <Grid item lg={4} md={4} sm={12}>
                <DialogActions>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => addMoreFields(inputData)}
                  >
                    ADD BOX
                  </Button>
                </DialogActions>
              </Grid>
              <Grid item lg={12}>
                <Divider />
              </Grid>
            </Grid>
          )}
          <form onSubmit={(e) => submitData(e)}>
            <Grid item lg={12}></Grid>
            {packagingDetails.length > 0 &&
            openShipmentPopup?.orderName == "rocketBox"
              ? packagingDetails.map((item, ind) => (
                  <DialogContent>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        lg={1}
                        sm={1}
                        md={1}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Typography variant="h4">{ind + 1}.</Typography>
                      </Grid>
                      <Grid item lg={3} sm={3} md={3}>
                        <TextField
                          type="number"
                          name="units"
                          value={item.units}
                          onChange={(e) => onValueChange(e, ind)}
                          placeholder="units"
                          label="units"
                          fullWidth
                          required
                          sx={{ mb: 3, ...stylesCSS.input }}
                        />
                      </Grid>
                      <Grid item lg={3} sm={3} md={3}>
                        <TextField
                          type="number"
                          name="weight"
                          value={item.weight}
                          onChange={(e) => onValueChange(e, ind)}
                          placeholder="total weight"
                          label="total weight"
                          fullWidth
                          required
                          sx={{ mb: 3, ...stylesCSS.input }}
                        />
                      </Grid>
                      <Grid item lg={3} sm={3} md={3}>
                        <TextField
                          type="number"
                          name="length"
                          value={item.length}
                          onChange={(e) => onValueChange(e, ind)}
                          placeholder="box length (inch)"
                          label="box length (inch)"
                          fullWidth
                          required
                          sx={{ mb: 3, ...stylesCSS.input }}
                        />
                      </Grid>
                      <Grid item lg={4} sm={4} md={4}>
                        <TextField
                          type="number"
                          name="height"
                          value={item.height}
                          onChange={(e) => onValueChange(e, ind)}
                          placeholder="box height (inch)"
                          label="box height (inch)"
                          fullWidth
                          required
                          sx={{ mb: 3, ...stylesCSS.input }}
                        />
                      </Grid>
                      {/*  <Grid item lg={3} sm={3} md={3}>

                                        <TextField
                                            type='number'
                                            name='width'
                                            value={item.width}
                                            onChange={(e) => onValueChange(e, ind)}
                                            placeholder='width'
                                            fullWidth
                                            required
                                        />

                                    </Grid> */}
                      <Grid item lg={4} sm={4} md={4}>
                        <TextField
                          type="number"
                          name="width"
                          value={item.width}
                          onChange={(e) => onValueChange(e, ind)}
                          placeholder="box width (inch)"
                          label="box width (inch)"
                          fullWidth
                          required
                          sx={{ mb: 3, ...stylesCSS.input }}
                        />
                      </Grid>

                      <Grid item lg={12}>
                        <Divider />
                      </Grid>
                    </Grid>
                  </DialogContent>
                ))
              : ""}
            <DialogActions>
              {openShipmentPopup?.orderName == "shiprocket" ? (
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  disabled={disableBtn}
                >
                  Submit
                </Button>
              ) : (
                <>
                  {packagingDetails.length !== 0 && (
                    <Button
                      color="primary"
                      type="submit"
                      variant="contained"
                      disabled={disableBtn}
                    >
                      Submit
                    </Button>
                  )}
                </>
              )}
            </DialogActions>
          </form>
        </DialogContent>

        {/*  <DialogActions>
                    <Button color="error" type='submit'>Cancel</Button>
                    <Button color="success" onClick={addAwbNumber}>Submit</Button>
                </DialogActions> */}
      </Dialog>
    </div>
  );
}
