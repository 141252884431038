import React, { useState } from "react";
import XLSX from "xlsx";
import { Grid, MenuItem, Select, Typography } from "@material-ui/core";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import { CSVLink } from "react-csv";

// const UploadInventory = () => {
const SheetJSFT = [
  "xlsx",
  "xlsb",
  "xlsm",
  "xls",
  "xml",
  "csv",
  "txt",
  "ods",
  "fods",
  "uos",
  "sylk",
  "dif",
  "dbf",
  "prn",
  "qpw",
  "123",
  "wb*",
  "wq*",
  "html",
  "htm",
]
  .map(function (x) {
    return "." + x;
  })
  .join(",");

const UploadAgentTarget = () => {
  const [file, setFile] = useState({});
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(true);



  const handleChange = (e) => {
    const files = e.target.files;
    if (files && files[0]) {
      setFile(files[0]);
      setDisable(false);
    }
  };

  const handleFile = () => {
    setLoading(true);
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? "binary" : "array",
        bookVBA: true,
        cellDates: true,
      });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws);
      console.log('dataaa',data)
      /* Update state */
      axios
        .post(process.env.REACT_APP_PATH + "/sales/uploadAgentTargetAndSale", {
          data: data,
        })
        .then((res) => {
          if (res?.data?.success) {
            window.alert("File Uploaded Successfully");
            setLoading(false);
          } else {
            setLoading(false);
            window.alert(res?.data || "Something went wrong");
          }
        })
        .catch((err) => {
          console.log("error", err);
          window.alert(err?.response?.data?.message || "Something went wrong");
        });
    };
    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  };

  return (
    <Grid container>
      <>
        <Grid item xs={12} lg={4} gap={2}>
          <div
            style={{
              border: "2px solid lightGrey",
              borderRadius: 5,
              marginLeft: 10,
              padding: 10,
            }}
          >
            <Typography variant="h3">Upload an Excel File</Typography>
            <br />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <input
                type="file"
                className="form-control"
                id="file"
                accept={SheetJSFT}
                onChange={handleChange}
              />
              <LoadingButton
                disabled={disable}
                loading={loading}
                loadingIndicator="Uploading..."
                onClick={handleFile}
                variant="contained"
              >
                Upload
              </LoadingButton>
            </div>
            <br />
          </div>
        </Grid>
      </>
    </Grid>
  );
};

export default UploadAgentTarget;
