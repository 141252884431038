import React, { useEffect, useState } from "react";
import SubCard from "../../ui-component/cards/SubCard";
import { Autocomplete, Button, Chip, Grid, TableContainer, TextField } from "@mui/material";
import axios from "axios";

import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
function AddBulkParentSeller() {
  const [parentNumber, setParentNumber] = useState("");
  const [childNumber, setChildNumber] = useState(null);
  const [pincode, setPincode] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const [allBulkCashSeller, setAllBulkCashSeller] = useState([]);
  const [allSellerByPincode, setAllSellerByPincode] = useState([]);

  const getAllBulkSeller = () => {
    axios
      .get(process.env.REACT_APP_PATH + "/seller/getCashBulkSeller")
      .then((res) => {
        if (res.data.success) {
          setAllBulkCashSeller(res.data.data);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getAllSellerbyPincode = () => {
    if (pincode.length !== 6) return;
    axios
      .post(process.env.REACT_APP_PATH + "/seller/getCashBulkSellerByPinCode", {
        pinCode: pincode,
      })
      .then((res) => {
        if (res.data.success) {
          setAllSellerByPincode(res.data.data);
        } else {
          alert(res.data?.message || "Someting went wrong");
        }
      })
      .catch((error) => {
        console.log("error", error);
        alert("Someting went wrong");
      });
  };

  const handleParentNumber = (value, type) => {
    console.log("value", value);
    console.log("typeee", typeof +value);
    if (!isNaN(value) && value.length <= 10) {
      if (type == "parent") {
        setParentNumber(+value);
      } else {
        setChildNumber(+value);
      }
    }
  };

  const handleSubmit = () => {
    setDisableButton(true);
    axios
      .post(process.env.REACT_APP_PATH + "/seller/addCashBulkSeller", {
        parentSellerpHONE: parentNumber.toString(),
        childSellerPhone: childNumber.phoneNumber,
      })
      .then((res) => {
        if (res.data.success) {
          getAllBulkSeller();
          setChildNumber(null)
          setDisableButton(false);
        }else{
            alert(res.data?.message||'someting went wrong')
            setDisableButton(false)
        }
      })
      .catch((error) => {
        console.log("errror", error);
        alert("Someting went wrong");
        setDisableButton(false);
      });
  };

  useEffect(() => {
    getAllSellerbyPincode();
  }, [pincode]);

  useEffect(() => {
    getAllBulkSeller();
  }, []);

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell>{row.name}</TableCell>
          <TableCell>{row.phoneNumber}</TableCell>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Phone Number</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.childrens.map((childs) => (
                      <TableRow>
                        <TableCell>{childs?.childSellerName}</TableCell>
                        <TableCell>{childs?.childSellerPhoneNumber}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  return (
    <SubCard>
          <Grid my={1}>
            <Typography>Search Seller by pincode</Typography>
            <TextField
              value={pincode}
              onChange={(t) => setPincode(t.target.value.trim())}
              placeholder="Enter Pincode"
            />
          </Grid>
      <Grid container spacing={2}>
        
        <Grid item xs={12} md={3} container>
        
          <TextField
            value={parentNumber}
            onChange={(t) =>
              handleParentNumber(t.target.value.trim(), "parent")
            }
            placeholder="Parent Number"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <Grid container alignItems={"center"}>
            {/* <TextField
              value={childNumber}
              onChange={(t) =>
                handleParentNumber(t.target.value.trim(), "child")
              }
              placeholder="Child Number"
            /> */}

            {/* <Button onClick={addChild} variant='contained' sx={{marginLeft:1}}>Add Child</Button> */}

            <Autocomplete 
                options={allSellerByPincode}
                value={childNumber}
                onChange={(e,newValue)=>{
                    console.log('newwww',newValue)
                    setChildNumber(newValue)
                }}
                getOptionLabel={(option) => option.phoneNumber}
                renderInput={(params) => <TextField {...params} label="Select Child" />}
                style={{width:240}}
                renderOption={(props, option) => {
                    const { key, ...optionProps } = props;
                    return (
                      <li key={key} {...optionProps}>
                        {option.phoneNumber}
                      </li>
                    );
                  }}
            />

            <Button
              disabled={
                childNumber==null ||
                parentNumber.toString().length !== 10 ||
                disableButton
              }
              sx={{ marginLeft: 1 }}
              onClick={handleSubmit}
              variant="contained"
            >
              Submit
            </Button>
          </Grid>
          {/* <div style={{
            display:'flex',
            marginTop:10,
            flexWrap:'wrap'
          }}>
            {allChildsNumber &&
              allChildsNumber.map((_, index) => <div style={{margin:"10px"}}>
                <Chip variant='outlined' label={_} onDelete={()=>removeChild(index)}> {_}</Chip>
                </div>)}
          </div> */}
          {/* <Button 
            onClick={handleSubmit}
            variant='outlined'
          >Submit</Button> */}
        </Grid>
      </Grid>
      <Grid style={{ marginTop: 50 }}>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {allBulkCashSeller.map((row) => (
                <Row key={row.name} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </SubCard>
  );
}

export default AddBulkParentSeller;
