import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  Switch,
} from "@material-ui/core";
import Chip from "../../ui-component/extended/Chip";
import axios from "axios";
import { Link } from "react-router-dom";
import SubCard from "../../ui-component/cards/SubCard";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { LoadingButton } from "@material-ui/lab";
import { CSVLink } from "react-csv";
import DataCard from "../Inventory/DataCard";
import { getDecryptDataFromLocal } from "../../utils/encrypt";
const label = { inputProps: { "aria-label": "Switch demo" } };
const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: 440,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },

  root: {
    width: "100%",
  },
  containerTable: {
    maxHeight: 440,
  },
}));

export default function OnlineOrderAbove20K() {
  let role = getDecryptDataFromLocal("setRole");
  const phoneNumber = getDecryptDataFromLocal("phoneNumber");
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [orderData, setOrderData] = useState([]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const [loading, setLoading] = useState(false);
  const [todaysOrderID, setTodaysOrderID] = useState("");
  const [floorManagerID, setFloorManagerID] = React.useState("");
  const [floorManagerList, setFloorManagerList] = React.useState([]);
  const [teamLeadID, setTeamLeadID] = React.useState("");
  const [teamLeadList, setTeamLeadList] = React.useState([]);
  const accessOfVerfifyButton = ['7532035127']
  const accessNumber = [
    "8448300516",
    "8076096127",
    "9773831187",
    "8882049957",
    "9719836187",
    "9891434580",
    "7011197257",
    "9650518684",
    "8368204349",
    "8810628760",
    "9310128750",
    "7827843126",
    "8851954552",
    "9354815727",
    "9354815727",
    "9625342361",
    "8851162831",
    '9811068834'
  ];

  let tempDate = new Date();

  const [date, setDate] = useState({
    start: new Date(tempDate.getFullYear(), tempDate.getMonth(), 2)
      .toISOString()
      .slice(0, 10),
    end: new Date().toISOString().slice(0, 10),
  });

  const getOrderAbove20K = () => {
    setLoading(true);
    axios
      .post(process.env.REACT_APP_PATH + "/role1/getOrderAbove20k", {
        startDate: date.start,
        endDate: date.end,
        tLId: teamLeadID,
        fMId: floorManagerID,
      })
      .then((res) => {
        if (res.data.success) {
          setOrderData(res.data?.data[0]?.data || []);
          setData(res.data?.data[0] || {});
          setLoading(false);
        }

        console.log("res of get order above 20", res.data);
      })
      .catch((error) => console.log("error", error));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getVerifyProduct = (id, isVerified) => {
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_PATH}/role4/verifyProductOrder`,
      data: {
        orderId: id,
        isVerified: isVerified,
      },
    })
      .then((res) => {
        if (res.data.success) {
          if (!isVerified) {
            console.log("isVerified", isVerified);
          } else {
            alert(`"Status Changed Successfully"`);
            getOrderAbove20K();
          }
        } else {
          alert("Something went wrong !");
        }
      })
      .catch((err) => {
        // console.log("err.response", err.response.data.message)
        alert(err?.response?.data?.message || "Something Went Wrong");
      });
  };

  const CheckOrder = (SelectedOrder) => {
    if (
      SelectedOrder.hasOwnProperty("isVerified") === false ||
      SelectedOrder.isVerified === true
    ) {
      return (
        <Switch
          {...label}
          onChange={() =>
            getVerifyProduct(
              SelectedOrder._id,
              false,
              SelectedOrder.source,
              SelectedOrder.phoneNumber,
              SelectedOrder?.sellerId
            )
          }
          checked={true}
          disabled={role == 13 || role == 14 || role == 17 ? false : true}
        />
      );
    } else if (SelectedOrder.isVerified === false) {
      return (
        <Switch
          {...label}
          onChange={() =>
            getVerifyProduct(
              SelectedOrder._id,
              true,
              SelectedOrder.source,
              SelectedOrder.phoneNumber,
              SelectedOrder?.sellerId
            )
          }
          checked={false}
          disabled={role == 13 || role == 14 || role == 17 ? false : true}
        />
      );
    }
  };

  const numericCommaSeparation = (value) => {
    return value.toString().replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",");
  };

  const LoadingComp = () => {
    if (loading) {
      return (
        <Paper style={{ display: "flex", justifyContent: "center" }}>
          <img
            alt="Example Alt"
            style={{ width: "200px", height: "130px" }}
            src="https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif"
            align="center"
          />
        </Paper>
      );
    }
  };

  const chip = (status) => {
    if (status === 1) {
      return <Chip label="Processing" size="small" chipcolor="primary" />;
    } else if (status === 2) {
      return <Chip label="Canceled" size="small" chipcolor="orange" />;
    } else if (status === 3) {
      return <Chip label="Complete" size="small" chipcolor="success" />;
    } else if (status === 4) {
      return <Chip label="RTO" size="small" chipcolor="orange" />;
    } else if (status === 6) {
      return <Chip label="Undelivered" size="small" chipcolor="orange" />;
    } else if (status == 8) {
      return <Chip label="Splitted" size="small" chipcolor="secondary" />;
    }
  };

  useEffect(() => {
    getOrderAbove20K();
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_PATH}/role2/floorManagerSearch`,
    })
      .then((res) => {
        setFloorManagerList(res.data.data);
      })
      .catch((err) => {
        alert("Something Went Wrong");
      });
  }, []);

  useEffect(() => {
    if (floorManagerID !== "") {
      axios({
        method: "GET",
        url: `${process.env.REACT_APP_PATH}/role2/TeamLeadSearch?immediateSeniorId=${floorManagerID}`,
      })
        .then((res) => {
          setTeamLeadList(res.data.data);
          setTeamLeadID("");
        })
        .catch((err) => {
          alert("Something Went Wrong");
        });
    } else {
      console.log("");
    }
  }, [floorManagerID]);

  useEffect(() => {
    if (role == "14") {
      axios({
        method: "GET",
        url: `${process.env.REACT_APP_PATH}/role2/TeamLeadSearch?immediateSeniorId=${floorManagerID}`,
      })
        .then((res) => {
          setTeamLeadList(res.data.data);
        })
        .catch((err) => {
          alert("Something Went Wrong");
        });
    } else {
      console.log("");
    }
  }, []);

  return (
    <div style={{ backgroundColor: "white" }}>
      <Grid
        container
        xs={12}
        spacing={2}
        item
        alignItems="center"
        bgcolor={"#fff"}
      >
        <Grid xs={12} sm={6} md={2} item>
          <TextField
            id="datetime-local"
            label="Start Date"
            type="date"
            defaultValue={date.start}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            style={{
              width: "100%",
            }}
            onChange={(e) =>
              setDate({
                ...date,
                start: e.target.value,
              })
            }
          />
        </Grid>
        <Grid xs={12} sm={6} md={2} item>
          <TextField
            id="datetime-local"
            label="End Date"
            type="date"
            defaultValue={date.end}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            style={{
              width: "100%",
            }}
            onChange={(e) =>
              setDate({
                ...date,
                end: e.target.value,
              })
            }
          />
        </Grid>
        {role !== "14" && role !== "13" ? (
          <Grid item xs={12} sm={6} md={3}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel
                id="demo-simple-select-helper-label"
                style={{ backgroundColor: "#fff" }}
              >
                Select Floor Manager
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                label="Select Floor Manager"
                onChange={(e) => setFloorManagerID(e.target.value)}
              >
                {floorManagerList.length > 0 ? (
                  floorManagerList.map((data, index) => (
                    <MenuItem key={index} value={data._id}>
                      {data.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="none">None To Select</MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
        ) : (
          ""
        )}

        {role !== "13" ? (
          <Grid item xs={12} sm={6} md={3}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel
                id="demo-simple-select-helper-label"
                style={{ backgroundColor: "#fff" }}
              >
                Select Team Lead
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                // value={seniorID}
                label="Select Team Lead"
                onChange={(e) => setTeamLeadID(e.target.value)}
              >
                {teamLeadList.length > 0 ? (
                  teamLeadList.map((data, index) => (
                    <MenuItem key={index} value={data._id}>
                      {data.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">None To Select</MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
        ) : (
          ""
        )}

        <Grid item xs={12} sm={6} md={2}>
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={() => getOrderAbove20K()}
            loadingIndicator="Loading..."
            style={{ width: "100%" }}
          >
            Submit
          </LoadingButton>
        </Grid>
      </Grid>
      <Grid container justifyContent={"space-between"}>
        <Grid item md={3} sm={6} xs={12} my={2}>
          <DataCard
            name="Sellers"
            bgc="#f5ddb1"
            value={numericCommaSeparation(data?.sellers || 0)}
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12} my={2}>
          <DataCard
            name="Total Amount"
            bgc="#d0aada"
            value={numericCommaSeparation(data?.totalAmount || 0)}
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12} my={2}>
          <DataCard
            name="Total Orders"
            bgc="#FF8787"
            value={numericCommaSeparation(data?.totalOrders || 0)}
          />
        </Grid>
      </Grid>

      <TableContainer className={classes.container}>
        {loading ? <LoadingComp /> : null}
        {orderData.length && loading == false > 0 ? (
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {/* {columns.map((column) => (
                                <TableCell sx={{ py: 3 }} key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                    {column.label}
                                </TableCell>
                            ))} */}
                <TableCell align="left">Ship Order Id</TableCell>
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">Phone Number</TableCell>
                <TableCell align="left">Total Amount</TableCell>
                <TableCell align="left">Date</TableCell>
                <TableCell align="left">Type</TableCell>
                <TableCell align="left">Status</TableCell>
                <TableCell>Automatic Assigned</TableCell>
                <TableCell align="left">Action</TableCell>
                <TableCell align="center">Number Verified</TableCell>

                <TableCell align="left">Audit Verification</TableCell>
              </TableRow>
            </TableHead>

            <TableBody
              style={{
                userSelect:
                  role == 1 || role == 2 || role == 4 ? "text" : "none",
              }}
            >
              {orderData.length && loading == false > 0
                ? orderData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((order, id) => {
                    return (
                      <TableRow
                        sx={{ py: 3 }}
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={id}
                      >
                        <TableCell
                          align="left"
                          style={{
                            textDecoration: "underline",
                          }}
                        >
                          <Link
                            to={`/orderdetails/${order._id}/false`}
                            style={{
                              textDecoration: "none",
                              color:
                                order.paymentMethod == 1 ? "#000" : "green",
                            }}
                            target="_blank"
                          >
                            {order.shiprocketOrderId}
                          </Link>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            color: order.isBeuPartnerOrder ? "red" : "#000",
                          }}
                        >
                          {order.name}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            color: order.isBeuPartnerOrder ? "red" : "#000",
                          }}
                        >
                          {order.phoneNumber}
                        </TableCell>
                        <TableCell align="left">
                          {Math.round(order.totalPayableAmount)}
                        </TableCell>
                        <TableCell align="left" style={{ minWidth: 200 }}>
                          {new Date(order.createdAt).toDateString()} -
                          {new Date(order.createdAt).toLocaleTimeString()}
                        </TableCell>
                        <TableCell align="left" style={{ minWidth: 150 }}>
                          {order.monthDiffFromFirstOrder >= 1
                            ? "Repeat Order"
                            : "New Order"}
                        </TableCell>
                        <TableCell align="left">
                          {/* {order.status} */}
                          {order.status === 3 && chip(3)}
                          {order.status === 2 && chip(2)}
                          {order.status === 4 && chip(4)}
                          {order.status === 6 && chip(6)}
                          {order.status === 8 && chip(8)}
                          {order.awbNumber !== undefined &&
                            order.status === 1 ? (
                            <Chip
                              label="In Transit"
                              size="small"
                              chipcolor="primary"
                            />
                          ) : order.status === 1 &&
                            order.awbNumber === undefined ? (
                            chip(1)
                          ) : null}

                          {/* {order.status === 1 && (
                          <Chip
                            label="Processing"
                            size="small"
                            chipcolor="primary"
                          />
                        )} */}
                        </TableCell>
                        <TableCell>
                          {order.isAutomaticAssigned &&
                            order.shiprocketAssignResp
                            ? "Yes"
                            : order.isAutomaticAssigned == false &&
                              order.shiprocketAssignResp != undefined
                              ? Object.keys(order.shiprocketAssignResp).length >
                                0
                                ? "Not Assign"
                                : "No"
                              : "No"}
                        </TableCell>
                        <TableCell align="left">
                          <Link
                            to={`/orderdetails/${order._id}/false`}
                            style={{
                              textDecoration: "none",
                              backgroundColor: "#f6f6f6",
                              padding: "10px 15px",
                            }}
                            target="_blank"
                          >
                            Details
                          </Link>
                        </TableCell>
                        <TableCell>
                          {(order.deliveryNumberVerified === false ||
                            order.deliveryNumberVerified === undefined ||
                            order.deliveryNumberVerified == "") && (
                              <Chip
                                label="Not verified"
                                size="small"
                                chipcolor="orange"
                              />
                            )}
                          {order.deliveryNumberVerified === true && (
                            <Chip
                              label="verified"
                              size="small"
                              chipcolor="success"
                            />
                          )}
                        </TableCell>
                        {role == 1 ||
                          role == 13 ||
                          role == 14 ||
                          role == 17 ? (
                          <>
                            {/* <TableCell>{order?.isVerified ? <Button color="success" >Verified</Button> : <Button variant="contained" onClick={() => getVerifyProduct(order._id)}>Click</Button>}</TableCell> */}
                            <TableCell>
                              {/* {order["isVerified"] && order.isVerified === true ? <Switch {...label} onChange={() => getVerifyProduct(order._id, false)} defaultChecked /> : <Switch {...label} onChange={() => getVerifyProduct(order._id, false)} />} */}
                              {CheckOrder(order)}
                            </TableCell>
                          </>
                        ) : null}
                      </TableRow>
                    );
                  })
                : null}
            </TableBody>
          </Table>
        ) : (
          <Grid item xs={12} style={{ width: "1000px" }}>
            {loading == false && (
              <Typography variant="h4" padding="15px 0 0 15px">
                Orders Not Found
              </Typography>
            )}
          </Grid>
        )}
      </TableContainer>
      {/* table pagination */}
      <TablePagination
        rowsPerPageOptions={[25, 100]}
        component="div"
        count={orderData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      // ActionsComponent={TablePaginationActions}
      />
    </div>
  );
}
