import React from "react";

// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
  IconUserCheck,
  IconBasket,
  IconMessages,
  IconMail,
  IconCalendar,
  IconNfc,
  IconShoppingCart,
} from "@tabler/icons";
import Locales from "../../ui-component/Locales";
import { getDecryptDataFromLocal } from "../../utils/encrypt";

// constant
const icons = {
  IconUserCheck: IconUserCheck,
  IconBasket: IconBasket,
  IconMessages: IconMessages,
  IconMail: IconMail,
  IconCalendar: IconCalendar,
  IconNfc: IconNfc,
  IconShoppingCart: IconShoppingCart,
};

//-----------------------|| Orders MENU ITEMS ||-----------------------//
const role = getDecryptDataFromLocal("setRole");
const agentType = localStorage.getItem("agentType");
const agentPhoneNumber = getDecryptDataFromLocal("phoneNumber");
const accessOfOffilneOrder = ["9560382051", "9719836187", "8650090359",'7532035127','9907125180'];
const accessOnlineBulkOrder = ['9911579990','8076096127'];
const accessOnlineBulkOrderRole = ['1'];
const onlineOrderAssessRole = ['1', '13', '14', '17'];
const onlineOrderByPhone = ["9719836187", "8650090359",'9907125180']
const OrganicVarifyOrdersByPhone = ["9719836187", "8650090359",'8076833686','8076096127','8810628760','8076652274','8595198905','7011576531',"8851162831"]
// const role = 12
var orders = [];
if (role == 5 && agentType == "OFFLINE") {
  orders = [
    {
      id: "Offline Orders",
      title: <FormattedMessage id="Offline Orders" />,
      type: "item",
      url: "/OfflineOrderList",
    },
  ];
} else if (role == 5) {
  orders = [
    {
      id: "OrderList",
      title: <FormattedMessage id="Seller Orders" />,
      type: "item",
      url: "/OrderList",
    },
  ];
} else if (role != 12) {
  if (role == 1 || role == 2 || role == 4) {
    orders = [
      {
        id: "OrderList",
        title: <FormattedMessage id="Seller Orders" />,
        type: "item",
        url: "/OrderList",
      },
      {
        id: "Audit Orders",
        title: <FormattedMessage id="Probation Agent Orders" />,
        type: "item",
        url: "/audit-orders",
      },
      {
        id: "account-profile",
        title: <FormattedMessage id="Customer Orders" />,
        type: "item",
        url: "/customerorderlist",
      },
      {
        id: "Status One Orders",
        title: <FormattedMessage id="Status One Orders" />,
        type: "item",
        url: "/statusoneorders",
      },
      {
        id: "Offline Orders",
        title: <FormattedMessage id="Offline Orders" />,
        type: "item",
        url: "/OfflineOrderList",
      },
      {
        id: "Rupifi Orders",
        title: <FormattedMessage id="Rupifi Orders" />,
        type: "item",
        url: "/RupifiOrderList",
      },
      {
        id: "Address Problem",
        title: <FormattedMessage id="Address Problem" />,
        type: "item",
        url: "/AddressProblemList",
      },
      {
        id: "Priority Orders",
        title: <FormattedMessage id="Priority Orders" />,
        type: "item",
        url: "/PriorityOrders",
      },
      {
        id: "Prepaid Cancelled Orders",
        title: <FormattedMessage id="Prepaid Cancelled Orders" />,
        type: "item",
        url: "/PrepaidCancelledOrders",
      },
      {
        id: "Return Pickup Orders",
        title: <FormattedMessage id="Return Pickup Orders" />,
        type: "item",
        url: "/return-pickup-orders",
      },
      {
        id: "Re-Dispatch Orders",
        title: <FormattedMessage id="Re-Dispatch Orders" />,
        type: "item",
        url: "/redispatch-orders",
      },
      {
        id: "Refund Prepaid Orders",
        title: <FormattedMessage id="Refund Prepaid Orders" />,
        type: "item",
        url: "/auto-refunded-prepaid-orders",
      },
      {
        id: "On Hold Orders",
        title: <FormattedMessage id="On Hold Orders" />,
        type: "item",
        url: "/on-hold-orders",
      },
      {
        id: "Group Orders",
        title: <FormattedMessage id="Group Orders" />,
        type: "item",
        url: "/group-orders",
      },
      {
        id: "Damage Product Orders",
        title: <FormattedMessage id="Damage Product Orders" />,
        type: "item",
        url: "/DamageProducts",
      },
      {
        id: "Rocket Box Orders",
        title: <FormattedMessage id="Rocket Box Orders" />,
        type: "item",
        url: "/rocketboxOrders",
      },
      onlineOrderAssessRole.includes(role) || onlineOrderByPhone.includes(agentPhoneNumber)
        ? {
            id: "Online Order Above 20K",
            title: <FormattedMessage id="Online Order Above 20K" />,
            type: "item",
            url: "/onlineorderabove20k",
          }
        : {},
      accessOfOffilneOrder.includes(agentPhoneNumber)
        ? {
            id: "Offline Unverify Orders",
            title: <FormattedMessage id="Offline Unverify Orders" />,
            type: "item",
            url: "/offlineUnverifiedOrder",
          }
        : {},
        (accessOnlineBulkOrder.includes(agentPhoneNumber)||accessOnlineBulkOrderRole.includes(role))
        ? {
            id: "Bulk Unverify Orders",
            title: <FormattedMessage id="Bulk Unverify Orders" />,
            type: "item",
            url: "/onlinebulklineUnverifiedOrder",
          }
        : {},
      OrganicVarifyOrdersByPhone.includes(agentPhoneNumber)
        ? {
            id: "Organic Unverify Orders",
            title: <FormattedMessage id="Organic Unverify Orders" />,
            type: "item",
            url: "/organicUnverifiedOrder",
          }
        : {},
    ];
  } else {
    orders = [
      {
        id: "OrderList",
        title: <FormattedMessage id="Seller Orders" />,
        type: "item",
        url: "/OrderList",
      },
      {
        id: "Audit Orders",
        title: <FormattedMessage id="Probation Agent Orders" />,
        type: "item",
        url: "/audit-orders",
      },
      {
        id: "account-profile",
        title: <FormattedMessage id="Customer Orders" />,
        type: "item",
        url: "/customerorderlist",
      },
      {
        id: "Status One Orders",
        title: <FormattedMessage id="Status One Orders" />,
        type: "item",
        url: "/statusoneorders",
      },
      {
        id: "Offline Orders",
        title: <FormattedMessage id="Offline Orders" />,
        type: "item",
        url: "/OfflineOrderList",
      },
      {
        id: "Rupifi Orders",
        title: <FormattedMessage id="Rupifi Orders" />,
        type: "item",
        url: "/RupifiOrderList",
      },
      {
        id: "Address Problem",
        title: <FormattedMessage id="Address Problem" />,
        type: "item",
        url: "/AddressProblemList",
      },
      {
        id: "Priority Orders",
        title: <FormattedMessage id="Priority Orders" />,
        type: "item",
        url: "/PriorityOrders",
      },
      {
        id: "Prepaid Cancelled Orders",
        title: <FormattedMessage id="Prepaid Cancelled Orders" />,
        type: "item",
        url: "/PrepaidCancelledOrders",
      },
      onlineOrderAssessRole.includes(role)|| onlineOrderByPhone.includes(agentPhoneNumber)
        ? {
            id: "Online Order Above 20K",
            title: <FormattedMessage id="Online Order Above 20K" />,
            type: "item",
            url: "/onlineorderabove20k",
          }
        : {},
      accessOfOffilneOrder.includes(agentPhoneNumber)
        ? {
            id: "Offline Unverify Orders",
            title: <FormattedMessage id="Offline Unverify Orders" />,
            type: "item",
            url: "/offlineUnverifiedOrder",
          }
        : {},
        (accessOnlineBulkOrder.includes(agentPhoneNumber)||accessOnlineBulkOrderRole.includes(role))
      ? {
          id: "Bulk Unverify Orders",
          title: <FormattedMessage id="Bulk Unverify Orders" />,
          type: "item",
          url: "/onlinebulklineUnverifiedOrder",
        }
      : {},
        OrganicVarifyOrdersByPhone.includes(agentPhoneNumber)
        ? {
            id: "Organic Unverify Orders",
            title: <FormattedMessage id="Organic Unverify Orders" />,
            type: "item",
            url: "/organicUnverifiedOrder",
          }
        : {},
    ];
  }
} else {
  if (role == 1 || role == 2 || role == 4) {
    orders = [
      {
        id: "OrderList",
        title: <FormattedMessage id="Seller Orders" />,
        type: "item",
        url: "/OrderList",
      },
      {
        id: "Audit Orders",
        title: <FormattedMessage id="Probation Agent Orders" />,
        type: "item",
        url: "/audit-orders",
      },
      {
        id: "account-profile",
        title: <FormattedMessage id="Customer Orders" />,
        type: "item",
        url: "/customerorderlist",
      },
      {
        id: "Status One Orders",
        title: <FormattedMessage id="Status One Orders" />,
        type: "item",
        url: "/statusoneorders",
      },
      {
        id: "Offline Orders",
        title: <FormattedMessage id="Offline Orders" />,
        type: "item",
        url: "/OfflineOrderList",
      },
      {
        id: "Rupifi Orders",
        title: <FormattedMessage id="Rupifi Orders" />,
        type: "item",
        url: "/RupifiOrderList",
      },
      {
        id: "Address Problem",
        title: <FormattedMessage id="Address Problem" />,
        type: "item",
        url: "/AddressProblemList",
      },
      {
        id: "Priority Orders",
        title: <FormattedMessage id="Priority Orders" />,
        type: "item",
        url: "/PriorityOrders",
      },
      {
        id: "Prepaid Cancelled Orders",
        title: <FormattedMessage id="Prepaid Cancelled Orders" />,
        type: "item",
        url: "/PrepaidCancelledOrders",
      },
      {
        id: "Damage Product Orders",
        title: <FormattedMessage id="Damage Product Orders" />,
        type: "item",
        url: "/DamageProducts",
      },
      {
        id: "Rocket Box Orders",
        title: <FormattedMessage id="Rocket Box Orders" />,
        type: "item",
        url: "/rocketboxOrders",
      },
      {
        id: "Online Order Above 20K",
        title: <FormattedMessage id="Online Order Above 20K" />,
        type: "item",
        url: "/onlineorderabove20k",
      },
    ];
  } else {
    orders = [
      {
        id: "OrderList",
        title: <FormattedMessage id="Seller Orders" />,
        type: "item",
        url: "/OrderList",
      },
      {
        id: "account-profile",
        title: <FormattedMessage id="Customer Orders" />,
        type: "item",
        url: "/customerorderlist",
      },
      (accessOnlineBulkOrder.includes(agentPhoneNumber)||accessOnlineBulkOrderRole.includes(role))
      ? {
          id: "Bulk Unverify Orders",
          title: <FormattedMessage id="Bulk Unverify Orders" />,
          type: "item",
          url: "/onlinebulklineUnverifiedOrder",
        }
      : {},
    ];
  }
}

orders.push({
  id: "Pre Order List",
  title: <FormattedMessage id="Pre Order List" />,
  type: "item",
  url: "/preOrderList",
})

export const Orders = {
  id: "All_Orders",
  title: <FormattedMessage id="All Orders" />,
  type: "group",
  children: [
    {
      id: "Orders",
      title: <FormattedMessage id="Orders" />,
      type: "collapse",
      icon: icons["IconShoppingCart"],
      children: orders,
    },
  ],
};
